import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const notificationOptionsInitialState = {
  message: '',
  open: false,
  type: null,
};

const modalOptionsInitialState = {
  open: false,
  closeButtonText: '',
  description: '',
  onClose: undefined,
  confirmAndCloseButtonText: '',
  title: '',
};

export const optimizeFormInitialState = {
  travelMode: 'drive',
  waypoints: [
    { id: uuidv4() },
    { id: uuidv4() },
  ],
};

export const globalSliceInitialState = {
  isSubscriptionModalOpen: false,
  lastDisplayedFreeAccountSubscriptionModalMilliseconds: 0,
  modalOptions: modalOptionsInitialState,
  notificationOptions: notificationOptionsInitialState,
  optimizeFormValues: optimizeFormInitialState,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: globalSliceInitialState,
  reducers: {
    clearWaypoints: (state) => {
      state.optimizeFormValues.waypoints = optimizeFormInitialState.waypoints;
    },
    closeNotification: (state) => {
      state.notificationOptions = notificationOptionsInitialState;
    },
    setLastDisplayedFreeAccountSubscriptionModalMilliseconds: (state, action) => {
      state.lastDisplayedFreeAccountSubscriptionModalMilliseconds = action.payload;
    },
    setSubscriptionModalOpen: (state, action) => {
      state.isSubscriptionModalOpen = action.payload;
    },
    setModalOptions: (state, action) => {
      state.modalOptions = action.payload.open === false
        ? modalOptionsInitialState
        : { ...action.payload };
    },
    setOptimizeFormValues: (state, action) => {
      state.optimizeFormValues = {
        ...optimizeFormInitialState,
        ...action.payload,
      };
    },
    showNotification: (state, action) => {
      state.notificationOptions = {
        ...action.payload,
        open: true,
      };
    },
  },
});

export const {
  clearWaypoints,
  closeNotification,
  setLastDisplayedFreeAccountSubscriptionModalMilliseconds,
  setModalOptions,
  setOptimizeFormValues,
  setSubscriptionModalOpen,
  showNotification,
} = globalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default globalSlice.reducer;
